<template>
    <div class="apply-wrap">
        <a-card class="light-shadow" :bordered="false">
            <template #title>
                <a-avatar shape="square" size="small" src="/images/logo.png" class="mr-2"></a-avatar>

                <span v-if="formData.auditState == auditStateEnum.none">{{ $t("account.complete_the_info_of_enterprise") }}</span>
                <span v-else-if="formData.auditState == auditStateEnum.pending">{{ $t("account.data_is_under_review") }}</span>
                <span v-else-if="formData.auditState == auditStateEnum.approved">{{ $t("account.data_has_been_reviewed") }}</span>
                <span v-else-if="formData.auditState == auditStateEnum.rejected">{{ $t("account.data_review_failed") }}</span>
            </template>
            <template #extra>
                <div class="langs-chooser">
                    <LangChooser></LangChooser>
                </div>
            </template>
            <div class="apply-form-wrap">
                <div class="apply-form">
                    <div
                        v-if="formData.auditState == auditStateEnum.pending"
                        class="text-center mb-3"
                    >
                        <small>{{$t("account.pending_tip")}}</small>
                    </div>
                    <div
                        v-if="formData.auditState == auditStateEnum.rejected"
                        class="text-center text-error mb-3"
                    >
                        <small>{{ formData.auditFailureReason }}</small>
                    </div>
                    <a-form ref="formRef" :model="formData" :label-col="{ span: 6 }" :rules="rules">
                        <a-form-item :label="$t('account.corporate_name')" name="shopName">
                            <a-input :disabled="!editable" 
                            v-model:value="formData.shopName"
                            :placeholder="$t('account.corporate_name_tip')"
                            ></a-input>
                        </a-form-item>
                        <a-form-item :label="$t('account.registration_number')" name="businessLicenseCode">
                            <a-input
                                :disabled="!editable"
                                v-model:value="formData.businessLicenseCode"
                            ></a-input>
                        </a-form-item>
                        <a-form-item :label="$t('account.business_license')" name="businessLicense">
                            <a-upload
                                :disabled="!editable"
                                list-type="picture-card"
                                 accept=" .jpeg,.jpg,.png,.pdf"
                                :show-upload-list="false"
                                :customRequest="handleUpdateLicense"
                            >
                                <div v-if="formData.businessLicense" class="licence-img">
                                    <img v-if="urlHelper.isImageUrl(formData.businessLicense)" :src="formData.businessLicense" />
                                    <span v-else-if="urlHelper.isPdfUrl(formData.businessLicense)" class="icon-link">
                                    <FilePdfOutlined/>
                                    </span>
                                    <span v-else class="icon-link">
                                    <LinkOutlined/>
                                    </span>
                                </div>
                                <div v-else>
                                    <plus-outlined></plus-outlined>
                                    <div class="ant-upload-text">{{ $t('common.upload_img') }}</div>
                                </div>
                            </a-upload>
                            <small style="color: #ababab;">{{ $t('account.business_license_tips') }}</small>
                        </a-form-item>
                        <a-form-item :label="$t('account.account_manager')" name="managerRealName">
                            <a-input :disabled="!editable" v-model:value="formData.managerRealName"></a-input>
                        </a-form-item>
                        <a-form-item :label="$t('account.phone_number')">
                            <a-input :disabled="true" :value="fullPhoneNumber"></a-input>
                        </a-form-item>
                    </a-form>
                    <div class="text-center" v-if="formData.auditState == auditStateEnum.none">
                        <a-button
                            type="primary"
                            @click="handleSave"
                            :loading="formData.saving"
                        >{{ $t("common.submit") }}</a-button>
                    </div>
                    <div
                        class="text-center"
                        v-else-if="formData.auditState == auditStateEnum.pending"
                    >
                        <a-button @click="handleLogout">{{ $t("common.logout") }}</a-button>
                    </div>
                    <div
                        class="text-center"
                        v-else-if="formData.auditState == auditStateEnum.approved"
                    >
                        <a-button type="primary" @click="handleEnterSystem">{{$t('account.start_use')}}</a-button>
                    </div>
                    <div
                        class="text-center"
                        v-else-if="formData.auditState == auditStateEnum.rejected"
                    >
                        <a-space v-if="formData.reSubmit">
                            <a-button
                                type="primary"
                                @click="handleSave"
                                :loading="formData.saving"
                                :disabled="!formData.modified"
                            >{{ $t("common.submit") }}</a-button>
                            <a-button @click="handleCancelEdit">{{ $t("common.cancel") }}</a-button>
                        </a-space>

                        <a-space v-else>
                            <a-button type="primary" @click="() => { formData.reSubmit = true; }">{{$t("account.modify")}}</a-button>
                            <a-button @click="handleLogout">{{ $t("common.logout") }}</a-button>
                        </a-space>
                    </div>
                    <div
                        class="text-center"
                         v-else>
                        <a-space>
                            <a-button @click="handleLogout">{{ $t("common.logout") }}</a-button>
                        </a-space>
                    </div>
                </div>
            </div>
        </a-card>
    </div>
</template>
<script>
import { reactive, onMounted, computed, ref, watch, nextTick } from 'vue'
import LangChooser from '../components/LangChooser.vue'
import store from "../../store/index";
import { Avatar, Form, Input, Row, Col, Button, Card, Space, Spin, Upload } from 'ant-design-vue'
import { uploadImg } from "../../api/modules/common/index.js"
import { checkSocialCreditCode } from "../../utils/general.js"
import { useI18n } from "vue-i18n/index"
import { registerShop, reviewShopInfoAgain } from "../../api/modules/account/index.js"
import { useRouter } from 'vue-router';
import { useTab } from '@/hooks/tabs/index';
import { auditState as auditStateEnum } from "../../enum/enum.json"
import urlHelper from "../../utils/urlHelper";


export default {
    name: "apply",
    components: {
        AAvatar: Avatar,
        AForm: Form,
        AFormItem: Form.Item,
        AInput: Input,
        ARow: Row,
        ACol: Col,
        AButton: Button,
        ACard: Card,
        ASpace: Space,
        AUpload: Upload,
        LangChooser,
    },
    setup() {
        const vueI18n = useI18n();
        const formRef = ref();
        const { delAllRoutes } = useTab();
        const router = useRouter();
        let formData = reactive({
            saving: false,
            uploading: false,
            reSubmit: false,
            modified: false,
        });

        const editable = computed(() => {
            // return false;
            return formData.auditState == auditStateEnum.none
                || (formData.auditState == auditStateEnum.rejected && formData.reSubmit)
        })

        const fullPhoneNumber = computed(() => {
            return (formData.phoneCountryCode ? "+" + formData.phoneCountryCode + " " : "")
                + formData.phoneNumber;
        });

        const loadApplyInfo = () => {
            Object.assign(formData, store.getters.userInfo);
            nextTick(() => {
                formData.modified = false;
            })
           // console.log(formData);
        }

        const handleUpdateLicense = (file) => {
            let uploadData = new FormData();
            uploadData.append("file", file.file);
            uploadData.append("functionModule", 12);
            formData.uploading = true;
            uploadImg(uploadData)
                .then((res) => {
                    formData.businessLicense = res.result[0].url;
                    if (formRef.value && formRef.value.validate) {
                        formRef.value.validate("businessLicense");
                    }
                })
                .catch(() => {

                })
                .finally(() => {
                    formData.uploading = false;
                })
        }

        const refreshAppluInfo = async () => {
            await store.dispatch("user/reloadUserInfo");
            formData.saving = false;
            formData.uploading = false;
            formData.reSubmit = false;
            loadApplyInfo();
        }

        watch(() => [formData.shopName,
        formData.businessLicense,
        formData.businessLicenseCode,
        formData.managerRealName], () => {
            formData.modified = true;
        })

        const handleSave = () => {
            formRef.value.validate()
                .then(() => {
                    let data = {
                        shopName: formData.shopName,
                        businessLicense: formData.businessLicense,
                        businessLicenseCode: formData.businessLicenseCode,
                        realName: formData.managerRealName,
                    };
                    formData.saving = true;
                    return formData.auditState == auditStateEnum.none
                        ? registerShop(data)
                        : reviewShopInfoAgain(data);
                })
                .then(() => {
                    formData.saving = false;
                    refreshAppluInfo();
                })
                .catch(() => {
                    formData.saving = false;

                })
        };

        const handleCancelEdit = () => {
            formData.reSubmit = false;
            formRef.value.clearValidate();
            loadApplyInfo();
        }

        const handleEnterSystem = async () => {
            await refreshAppluInfo();
            delAllRoutes();
            router.push({ path: "/dashboard" });
        }

        const handleLogout = async () => {
            await store.dispatch("user/logout")
            router.push("/login");
        }

        const rules = {
            shopName: [
                {
                    required: true, message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t("account.corporate_name")])
                    }, trigger: ['change', 'blur']
                }
            ],
            businessLicense: [
                {
                    required: true, message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t("account.business_license")])
                    }, trigger: ['change', 'blur']
                }
            ],
            businessLicenseCode: [
                {
                    required: true, message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t("account.registration_number")])
                    }, trigger: ['change', 'blur']
                },
                {
                    validator: async (rule, value) => {
                        if (value) {
                            //中国的验证
                            if(formData.phoneCountryCode == "86"){
                                //中国香港的验证
                                var hkCodeReg = /^[0-9]{7}$/;
                                if (checkSocialCreditCode(value)||hkCodeReg.test(value)) 
                                {
                                    return Promise.resolve();
                                }
                            }else{
                                var codeReg = /^[0-9a-zA-Z\-]{5,30}$/;
                                if(codeReg.test(value)){
                                    return Promise.resolve();
                                }
                            }
                            return Promise.reject();
                        }
                        return Promise.resolve();
                    },
                    message: () => { return vueI18n.t("account.registration_number_format_error"); },
                    trigger: ['change', 'blur']
                }
            ],
            managerRealName: [
                {
                    required: true,
                    min: 2,
                    message: () => {
                        return vueI18n.t("common.p0_is_required", [vueI18n.t("账户负责人")])
                    }, trigger: ['change', 'blur']
                }
            ],
        }

        onMounted(() => {
            refreshAppluInfo();
            //loadApplyInfo();
        });

        return {
            formData,
            formRef,
            editable,
            fullPhoneNumber,

            handleUpdateLicense,
            handleSave,
            handleCancelEdit,
            handleEnterSystem,
            handleLogout,

            rules,
            auditStateEnum,
            urlHelper
        };
    }
}
</script>
<style lang="less" scoped>
.apply-wrap {
    width: 600px;
    max-width: 100%;
}
.apply-form-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    .apply-form {
        width: 400px;
        max-width: 100%;
    }
}
.licence-img {
    max-width: 86px;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    :deep(img) {
        max-height: 86px;
        max-width: 86px;
    }
}

 .icon-link {
    font-size: 40px;
    color: @primary-color;
  }
</style>